import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFound from './components/NotFound.vue'
import Login from './views/Login.vue'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
Vue.use(Router)

//https://stackoverflow.com/questions/64298276/load-dynamic-vue-routes-from-json-file
//https://router.vuejs.org/api/#router-addroutes

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/login',
      name: 'login',
      component: Login
    },{
      path: '/ledgergroup',
      name: 'ledgergroup',
      component: () => import('./views/account/ledgergroup/LedgerGroupView.vue')
    },
    {
      path: '/ledger',
      name: 'ledger',
      component: () => import('./views/account/ledger/LedgerView.vue')
    },{
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },{
      path: '/newnote',
      name: 'newnote',
      component: () => import('@/components/v1/note/NoteForm.vue')
    },{
      path: '/opennote',
      name: 'opennote',
      component: () => import('./views/tms/office/note/NoteView.vue')
    },{
      path: '/supplier',
      name: 'supplier',
      component: () => import('./views/tms/master/supplier/SupplierView.vue')
    },{
      path: '/agent',
      name: 'agent',
      component: () => import('./views/tms/master/agent/AgentView.vue')
    },{
      path: '/buyer',
      name: 'buyer',
      component: () => import('./views/tms/master/buyer/BuyerView.vue')
    },{
      path: '/itemgroup',
      name: 'itemgroup',
      component: () => import('./views/tms/master/itemgroup/ItemGroupView.vue')
    }, {
      path: '/componentsgroup',
      name: 'componentsgroup',
      component: () => import('./views/tms/master/componentgroup/ComponentGroupView.vue')
    },{
      path: '/components',
      name: 'components',
      component: () => import('./views/tms/master/component/ComponentView.vue')

    }, {
      path: '/itemcategory',
      name: 'itemcategory',
      component: () => import('./views/tms/master/category/CategoryView.vue')
    }, {
      path: '/itembrand',
      name: 'itembrand',
      component: () => import('./views/tms/master/brand/BrandView.vue')
    }, {
      path: '/item',
      name: 'item',
      component: () => import('./views/tms/master/item/ItemView.vue')
    }, {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/tms/master/terms/TermsView.vue')
    },{
      path: '/project',
      name: 'project',
      component: () => import('./views/tms/master/project/ProjectView.vue')
    },{
      path: '/designation',
      name: 'designation',
      component: () => import('./views/tms/master/designation/DesignationView.vue')
    },{
      path: '/newcashpayment',
      name: 'newcashpayment',
      component: () => import('./views/account/cashpayment/CashPaymentForm')
    },{
      path: '/opencashpayment',
      name: 'opencashpayment',
      component: () => import('./views/account/cashpayment/CashPaymentView.vue')
    },{
      path: '/newjournal',
      name: 'newjournal',
      component: () => import('./views/account/journal/JournalForm.vue')
    },{
      path: '/openjournal',
      name: 'openjournal',
      component: () => import('./views/account/journal/JournalView.vue')
    },{
      path: '/newpayment',
      name: 'newpayment',
      component: () => import('./views/account/payment/PaymentForm.vue')
    },{
      path: '/openpayment',
      name: 'openpayment',
      component: () => import('./views/account/payment/PaymentView.vue')
    },{
      path: '/newreceipt',
      name: 'newreceipt',
      component: () => import('./views/account/receipt/ReceiptForm.vue')
    },{
      path: '/openreceipt',
      name: 'openreceipt',
      component: () => import('./views/account/receipt/ReceiptView.vue')
    },{
      path: '/newcontra',
      name: 'newcontra',
      component: () => import('./views/account/contra/ContraForm.vue')
    },{
      path: '/opencontra',
      name: 'opencontra',
      component: () => import('./views/account/contra/ContraView.vue')
    },{
      path: '/newindent',
      name: 'indent',
      component: () => import('./components/v1/indent/IndentForm.vue')
    },{
      path: '/openindent',
      name: 'openindent',
      component: () => import('./views/tms/office/indent/IndentView.vue')
    },{
      path: '/newbudget',
      name: 'newbudget',
      component: () => import('@/components/v1/budget/BudgetForm.vue')
    },{
      path: '/openbudget',
      name: 'openbudget',
      component: () => import('./views/tms/office/budget/BudgetView.vue')
    },{
      path: '/newquote',
      name: 'newquote',
      component: () => import('@/components/v1/quote/QuotationForm.vue')
    },{
      path: '/openquote',
      name: 'openquote',
      component: () => import('./views/tms/office/quote/QuotationView.vue')
    }, {
      path: '/hr-designation',
      name: 'hr-designation',
      component: () => import('./views/hr/designation/DesignationView.vue')
    }, {
      path: '/hr-emp',
      name: 'hr-emp',
      component: () => import('./views/hr/employee/EmployeeView.vue')
    },{
      path: '/hr-shift',
      name: 'hr-shift',
      component: () => import('@/components/v1/hr/shift/ShiftForm.vue')
    }, {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/fms/company/CompanyView.vue')
    },{
      path: '/gen-settings',
      name: 'Settings',
      component: () => import('@/views/tools/general/SettingsView.vue')

    },{
      path: '/openrawmaterial',
      name: 'openrawmaterial',
      component: () => import('@/views/asquare/rawmaterials/RawMaterialView.vue')
    },{
      path: '/openreceipts',
      name: 'openreceipts',
      component: () => import('@/views/asquare/receipt/ReceiptView.vue')
    },{
      path: '/openproject',
      name: 'openproject',
      component: () => import('@/views/asquare/project/ProjectView.vue')
    },{
      path: '/openlabours',
      name: 'openlabours',
      component: () => import('@/views/asquare/labour/LabourView.vue')
    },{
      path: '/newbill',
      name: 'newbill',
      component: () => import('@/views/asquare/bill/BillForm.vue')
    },{
      path: '/newsupplier',
      name: 'newsupplier',
      component: () => import('@/views/asquare/supplier/SupplierForm.vue')
    },{
      path: '/opensuppliers',
      name: 'opensuppliers',
      component: () => import('@/views/asquare/supplier/SupplierView.vue')
    }, {
      path: '/settlement',
      name: 'settlement',
      component: () => import('@/views/xpos/bills/CouponView.vue')
    }, {
      path: '/bills',
      name: 'bills',
      component: () => import('@/views/xpos/bills/BillView.vue'),
      // beforeEnter (to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/cancelbills',
      name: 'cancelbills',
      component: () => import('@/views/xpos/bills/CancelBillView.vue'),
      // beforeEnter (to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      //digitalbills
      path: '/digitalbills',
      name: 'digitalbills',
      component: () => import('@/views/xpos/bills/DigitalBillView.vue'),
      // beforeEnter (to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/priceupdate',
      name: 'priceupdate',
      component: () => import('@/views/xpos/priceupdate/PriceUpdateForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newinvoice',
      name: 'newinvoice',
      component: () => import('@/views/inventory/purchase/InvoiceForm.vue'),
      // component: () => import('@/views/xpos/purchase/PurchaseForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openinvoice',
      name: 'openinvoice',
      component: () => import('@/views/inventory/purchase/InvoiceView.vue'),
    }, {
      path: '/newfuelinvoice',
      name: 'newfuelinvoice',
      component: () => import('@/views/xpos/fuelpurchase/FuelPurchaseForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/openfuelinvoice',
      name: 'openfuelinvoice',
      component: () => import('@/views/xpos/fuelpurchase/FuelPurchaseView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newsaleinvoice',
      name: 'newsaleinvoice',
      component: () => import('@/views/xpos/sale/SaleInvoiceForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opensaleinvoice',
      name: 'opensaleinvoice',
      component: () => import('@/views/xpos/sale/SaleInvoiceView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newsalereceipt',
      name: 'newsalereceipt',
      component: () => import('@/views/xpos/sale/SaleReceipt.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opensalereceipt',
      name: 'opensalereceipt',
      component: () => import('@/views/xpos/sale/SaleReceiptView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newtransfer',
      name: 'newtransfer',
      component: () => import('@/views/xpos/transfer/TransferForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opentransfer',
      name: 'opentransfer',
      component: () => import('@/views/xpos/transfer/TransferView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newhopayment',
      name: 'newhopayment',
      component: () => import('@/views/xpos/hopayment/HoPaymentForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/openhopayment',
      name: 'openhopayment',
      component: () => import('@/views/xpos/hopayment/HoPaymentView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newexpense',
      name: 'newexpense',
      component: () => import('@/views/xpos/expense/ExpenseForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/openexpense',
      name: 'openexpense',
      component: () => import('@/views/xpos/expense/ExpenseView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newdigitalpay',
      name: 'newdigitalpay',
      component: () => import('@/views/xpos/digitalpayment/DigitalPaymentForm'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opendigitalpay',
      name: 'opendigitalpay',
      component: () => import('@/views/xpos/digitalpayment/DigitalPaymentView'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newpartyreceipt',
      name: 'newpartyreceipt',
      component: () => import('@/views/xpos/partyreceipt/PartyReceiptForm'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/openpartyreceipt',
      name: 'openpartyreceipt',
      component: () => import('@/views/xpos/partyreceipt/PartyReceiptView'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newtesting',
      name: 'newtesting',
      component: () => import('@/views/xpos/testentry/TestEntryForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opentesting',
      name: 'opentesting',
      component: () => import('@/views/xpos/testentry/TestEntryView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newvaultpayment',
      name: 'newvaultpayment',
      component: () => import('@/views/xpos/vaultpayment/VaultPaymentForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openvaultpayment',
      name: 'openvaultpayment',
      component: () => import('@/views/xpos/vaultpayment/VaultPaymentView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newvaultreceipt',
      name: 'newvaultreceipt',
      component: () => import('@/views/xpos/vaultreceipt/VaultReceiptForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openvaultreceipt',
      name: 'openvaultreceipt',
      component: () => import('@/views/xpos/vaultreceipt/VaultReceiptView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newotherreceipt',
      name: 'newotherreceipt',
      component: () => import('@/views/xpos/receipt/ReceiptForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openotherreceipt',
      name: 'openotherreceipt',
      component: () => import('@/views/xpos/receipt/ReceiptView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/newadjustment',
      name: 'newadjustment',
      component: () => import('@/views/xpos/stockadjust/StockAdjustForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openadjustment',
      name: 'openadjustment',
      component: () => import('@/views/xpos/stockadjust/StockAdjustView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/newmeterreading',
      name: 'newmeterreading',
      component: () => import('@/views/xpos/meterreading/MeterReadingForm.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    }, {
      path: '/openmeterreading',
      name: 'openmeterreading',
      component: () => import('@/views/xpos/meterreading/MeterReadingView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/terminal',
      name: 'terminal',
      component: () => import('@/views/xpos/terminal/TerminalView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/cashier',
      name: 'cashier',
      component: () => import('@/views/xpos/cashier/CashierView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/saleman',
      name: 'saleman',
      component: () => import('@/views/xpos/salesman/SalesManView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      //dipchart
      path: '/dipchart',
      name: 'dipchart',
      component: () => import('@/views/xpos/dipchart/DipChartView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/branches',
      name: 'branches',
      component: () => import('@/views/xpos/outlet/OutletView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/tank',
      name: 'tank',
      component: () => import('@/views/xpos/tank/TankView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/vehicle',
      name: 'vehicle',
      component: () => import('@/views/xpos/vehicle/VehicleView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/vehiclecategory',
      name: 'vehiclecategory',
      component: () => import('@/views/xpos/vehiclecateogry/VehicleCategoryView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/nozzle',
      name: 'nozzle',
      component: () => import('@/views/xpos/nozzle/NozzleView.vue'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    // },{
    //   path: '/posbills',
    //   name: 'posbills',
    //   component: () => import('@/views/reports/xpos/posbills.vue'),
    //   beforeEnter(to, from, next) {
    //     userService.routeProtecter(to, from, next);
    //   }
    },{
      path: '/newdipentry',
      name: 'newdipentry',
      component: () => import('@/views/xpos/dipentry/DipEntryForm'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/opendipentry',
      name: 'opendipentry',
      component: () => import('@/views/xpos/dipentry/DipEntryView'),
      // beforeEnter(to, from, next) {
      //   userService.routeProtecter(to, from, next);
      // }
    },{
      path: '/newbanksettlement',
      name: 'newbanksettlement',
      component: () => import('@/views/xpos/banksettlement/BankSettlementForm'),
    },{
      path: '/openbanksettlement',
      name: 'openbanksettlement',
      component: () => import('@/views/xpos/banksettlement/BankSettlementView'),
    },{
      path: '/newfleetsettlement',
      name: 'newfleetsettlement',
      component: () => import('@/views/xpos/fleetsettlement/FleetSettlementForm'),
    },{
      path: '/openfleetsettlement',
      name: 'openfleetsettlement',
      component: () => import('@/views/xpos/fleetsettlement/FleetSettlementView'),
    },{
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/xpos/buyer/BuyerView'),
    },{
      path: '/cashiers',
      name: 'cashiers',
      component: () => import('@/views/xpos/cashier/CashierView'),
    },{
      path: '/tanks',
      name: 'tanks',
      component: () => import('@/views/xpos/tank/TankView'),
    },{
      path: '/dipcharts',
      name: 'dipcharts',
      component: () => import('@/views/xpos/dipchart/DipChartView'),
    },{
      path: '/nozzles',
      name: 'nozzles',
      component: () => import('@/views/xpos/nozzle/NozzleView'),
    },{
      path: '/terminals',
      name: 'terminals',
      component: () => import('@/views/xpos/terminal/TerminalView'),
    },{
      path: '/report_sale',
      name: 'report_sale',
      component: () => import('@/views/xpos/reports/SaleHistory'),
    }, {
      path: '/report_meterreading',
      name: 'report_meterreading',
      component: () => import('@/views/xpos/reports/MeterReading'),
    },{
      path: '/report_party_statement',
      name: 'report_party_statement',
      component: () => import('@/views/xpos/reports/PartyStatement'),
    },{
      path: '/report_party_balance',
      name: 'report_party_balance',
      component: () => import('@/views/xpos/reports/PartyBalance'),
    },{
      path: '/*',
      name: 'not found',
      component: NotFound,
    }
  ],
  base: '/',
  fallback: true,
})
