<template>
  <button type="button" class="btn btn-outline-success" @click="clicked" >
    <i class="icon-database-add mr-2"></i> {{title}}
  </button>
</template>

<script>

export default {
  name: 'SaveButton',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Save',
    },
  },
  created () {
    // this.$props.title = "Clicked!!!";
  },
  methods:{
    clicked() {
      const self = this;
      self.$emit('handle_save_action');
    },
  }
}
</script>

<style scoped>

</style>
