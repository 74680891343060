<template>

    <FocusTrap>


      <!-- Content area -->
      <div class="content d-flex justify-content-center align-items-center">

          <!-- Login form -->
          <form class="login-form" @submit.prevent="handleSubmit">
              <div class="card mb-0">
                  <div class="card-body">
                      <div class="text-center mb-3">
                          <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                          <h5 class="mb-0">Login to your account</h5>
                          <span class="d-block text-muted">Enter your User credentials below</span>
                      </div>

                      <div class="form-group form-group-feedback form-group-feedback-left">
                          <input id="txtWorkingdate"  type="date" class="form-control" placeholder="Working Date"  autocomplete="off" v-model="workingdate" >
                          <div class="form-control-feedback">
                              <i class="icon-calendar text-muted"></i>
                          </div>
                      </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                      <select id="cmpCompany"  class="form-control" v-model="cmp_id" v-on:change="company_change" >
                        <option v-for="company in companies" v-bind:value="company.id">
                          {{ company.name }}
                        </option>
                      </select>

                      <div class="form-control-feedback">
                        <i class="icon-city text-muted"></i>
                      </div>
                    </div>

                    <div class="form-group form-group-feedback form-group-feedback-left">
                      <input id="username"  type="text" class="form-control" placeholder="Username" v-model="username" autocomplete="off" >
                      <div class="form-control-feedback">
                        <i class="icon-user text-muted"></i>
                      </div>
                    </div>

                      <div class="form-group form-group-feedback form-group-feedback-left">
                          <input type="password" class="form-control" placeholder="Password" v-model="password">
                          <div class="form-control-feedback">
                              <i class="icon-lock2 text-muted"></i>
                          </div>
                      </div>

                    <div class="form-group d-flex align-items-center">
                      <div class="form-check mb-0">
                        <label class="form-check-label">
                          <input  id="txtremember" name="remember" type="checkbox" class="form-check-input-styled" checked="">
                          Remember
                        </label>
                      </div>
                      <!--<a href="login_password_recover.html" class="ml-auto">Forgot password?</a>-->
                    </div>



                      <div class="form-group">
                          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block">Log In
                              <i class="icon-circle-right2 ml-2"></i>
                          </button>
                      </div>

                  </div>
              </div>
          </form>
          <!-- /login form -->

      </div>
      <!-- /content area -->
    </FocusTrap>

</template>

<script>
    import {userService} from '@/store/auth-header.js'
    import { store } from '@/store/store.js'
    import moment from 'moment-timezone'
    import SaveButton from '../components/core/buttons/SaveButton'
    export default {
      name: "Login",
      components: { SaveButton },
      data() {
            return {
              companies:[],
              workingdate: moment().format('YYYY-MM-DD'),
              username: '',
              password: '',
              cmp_id : 1,
            }
        },
        store,
        props:["loggedin"],
        created () {
            userService.logout();
            this.$parent.loggedin = false;
        },
        mounted () {
          this.loadCompanies();
          $('.form-check-input-styled').uniform();
            this.$parent.loggedin = false;
            $('.sidebar').hide();
            $('#user_msg').text('FMS!, Welcomes You!!');
            $('#username').focus();
        },
        methods : {
            loadCompanies () {
              const self = this;

              const requestOptions = {
                method: 'GET',
                mode:"cors",
              };

              // alert(`${process.env.VUE_APP_ROOT_API}api/activecompanies`);
              fetch(`${process.env.VUE_APP_ROOT_API}api/activebranches`,requestOptions).then(userService.handleResponse).then(function (resp) {

                if(resp.ok) {
                  self.$data.companies = resp.data;
                } else {
                  swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
                }
              }).catch(function (err) {
                swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
              });
            },
          company_change(e){
              // alert(this.$data.cmp_id);
          },
          handleSubmit (e) {
              const self = this;

              this.submitted = true;
              const email = this.username;
              const password = this.password; //forge_sha256(this.password);


              //credentials: 'include',
              const requestOptions = {
                  method: 'POST',
                  mode:'cors',
                  headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                  body: JSON.stringify({"email": email, "password":password, "cmp_id": self.$data.cmp_id})
              };

              $('.login-form').block({
                  message: '<i class="icon-spinner2 spinner"></i>',
                  overlayCSS: {
                      backgroundColor: '#fff',
                      opacity: 0.8,
                      cursor: 'wait',
                      'box-shadow': '0 0 0 1px #ddd'
                  },
                  css: {
                      border: 0,
                      padding: 0,
                      backgroundColor: 'none'
                  }
              });

              return fetch(`${process.env.VUE_APP_ROOT_API}api/login`, requestOptions)
                .then(userService.handleResponse)
                .then(resp => {

                  // login successful if there's a user in the response
                  if (resp.ok) {

                    store.state.user.company.id = self.$data.cmp_id;
                    // store user details and basic auth credentials in local storage
                    // to keep user logged in between page refreshes
                    self.$store.state.user = resp.data;
                    store.state.user = resp.data;

                    localStorage.setItem('xposuser', JSON.stringify(resp.data));
                    localStorage.setItem('finyear', resp.data.finyear);
                    localStorage.setItem('xpostoken', resp.data.token);

                    // router.push(self.$route.query.returnUrl || '/');
                    // alert(self.$route.query.returnUrl || '/');
                    $('.login-form').unblock();

                    window.location = self.$route.query.returnUrl || '/';
                  }else{
                    swal( {type:'info' , title:'!Oops', text: resp.msg, timer:5000  });
                  }

                }).catch(err => {
                  $('.login-form').unblock();
                  swal( {type:'info' , title:'!Oops', text: err.toString(), timer:5000  });
                }).finally(function () {
                  $('.login-form').unblock();
                });
              }
        }
    }
</script>

<style scoped>
  input:focus { background: #feff00; }
  textarea:focus { background: #feff00; }
  select:focus { background: #feff00; }
  button:focus { background: #feff00; }
</style>
